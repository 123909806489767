<template>
  <div style="height: 100vh">
    <div
      class="h-100 align-items-center justify-content-center d-none d-md-flex"
    >
      <b-card
        style="border-radius: 20px; border: 1px solid lightgrey"
        class="px-1 m-0 d-none d-md-block"
      >
        <div class="mt-1">
          <b-link :to="{ name: 'Login' }">
            <img :src="this.appLogoImage" img-fluid width="200" height="75"
          /></b-link>
        </div>
        <div class="pt-2 pb-3">
          <img src="@/assets/images/icons/Tick.svg" img-fluid />
        </div>

        <b-card-title
          title-tag="h2"
          class="font-weight-bolder text-colorBlack mb-50 pr-5 mr-5"
        >
          Details Submitted Successfully
        </b-card-title>
        <b-card-text class="text-colorGray" style="font-size: 0.8rem">
          Thank you for providing your account creation details. Our team will
          review the information you've <br />
          submitted, and we will contact you soon to confirm the setup of your
          new account. If you have <br />
          any urgent questions or require immediate assistance, please don't
          hesitate to reach out to our <br />
          support team. We appreciate your interest in our service and look
          forward to welcoming you on <br />
          board!
        </b-card-text>

        <b-button
          type="submit"
          variant="colorBlue"
          block
          @click="validateForm"
          class="mt-3"
          :to="{ name: 'Login' }"
        >
          Back To Home
        </b-button>
      </b-card>
    </div>

    <div class="d-block d-md-none h-100">
      <b-row style="height: 10%">
        <b-col md="12" style="border-bottom: 2px solid lightgrey">
          <b-row>
            <b-col sm="12" class="p-0">
              <b-navbar toggleable="lg" class="px-2">
                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
                <b-collapse id="nav-collapse" is-nav>
                  <b-navbar-nav>
                    <b-nav-item href="#">Link</b-nav-item>
                  </b-navbar-nav>

                  <!-- Right aligned nav items -->
                  <b-navbar-nav class="ml-auto">
                    <b-nav-item-dropdown text="Lang" right>
                      <b-dropdown-item href="#">EN</b-dropdown-item>
                      <b-dropdown-item href="#">ES</b-dropdown-item>
                      <b-dropdown-item href="#">RU</b-dropdown-item>
                      <b-dropdown-item href="#">FA</b-dropdown-item>
                    </b-nav-item-dropdown>

                    <b-nav-item-dropdown right>
                      <template #button-content>
                        <em>User</em>
                      </template>
                      <b-dropdown-item href="#">Profile</b-dropdown-item>
                      <b-dropdown-item href="#">Sign Out</b-dropdown-item>
                    </b-nav-item-dropdown>
                  </b-navbar-nav>
                </b-collapse>
                <b-navbar-brand href="#">
                  <img
                    :src="this.appLogoImage"
                    img-fluid
                    width="100"
                    height="75"
                /></b-navbar-brand>
              </b-navbar>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div class="px-2 py-auto" style="height: 90%">
        <div class="mt-5 pt-2">
          <div class="mt-1">
            <b-link :to="{ name: 'Login' }">
              <img :src="this.appLogoImage" img-fluid width="200" height="75"
            /></b-link>
          </div>
          <div class="pt-2 pb-3">
            <img src="@/assets/images/icons/Tick.svg" img-fluid />
          </div>

          <b-card-title
            title-tag="h2"
            class="font-weight-bolder text-colorBlack mb-50 pr-5 mr-5"
          >
            Details Submitted Successfully
          </b-card-title>
          <b-card-text class="text-colorGray" style="font-size: 0.8rem">
            Thank you for providing your account creation details. Our team will
            review the information you've <br />
            submitted, and we will contact you soon to confirm the setup of your
            new account. If you have <br />
            any urgent questions or require immediate assistance, please don't
            hesitate to reach out to our <br />
            support team. We appreciate your interest in our service and look
            forward to welcoming you on <br />
            board!
          </b-card-text>

          <b-button
            type="submit"
            variant="colorBlue"
            block
            @click="validateForm"
            class="mt-3"
            :to="{ name: 'Login' }"
          >
            Back To Home
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { $themeConfig } from "@themeConfig";
import { mapActions, mapGetters } from "vuex";
import util from "@/util.js";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility, util],
  data() {
    return {
      appName: "",
      appFullName: "",
      appFullName: "",
      password: "",
      username: "",
      required,
    };
  },
  created() {
    this.appName = $themeConfig.app.appName;
    this.appFullName = $themeConfig.app.appFullName;
    this.appLogoImage = $themeConfig.app.appLogoImage;
  },
  methods: {
    ...mapActions({ login: "appData/login" }),
    async validateForm() {
      const success = await this.$refs.loginFormValidation.validate();
      if (success) {
        await this.submit();
      }
    },
    async submit() {
      try {
        const res = await this.login({
          username: this.username,
          password: this.password,
        });
        if (res.status === 200) {
          this.$swal({
            title: "Logged in successfully",
            icon: "success",
          });
          this.$router.push({ name: "Candidate" });
        }
      } catch (error) {
        this.displayError(error);
      }
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    ...mapGetters({ hasRole: "appData/hasRole" }),
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>

<style scoped></style>
